import React from 'react';

import {
  Box,
  Button,
  Sheet,
  Stack,
  Table,
  Typography,
  LinearProgress,
  IconButton,
  CircularProgress,
} from '@mui/joy';

import Dropzone from 'react-dropzone';
import { IconDownload, IconPlus, IconTrash } from '@tabler/icons-react';

import { useCareerCanvas } from '../../hooks/useCareerCanvas';
import { timeSince, newDate } from '../../util/dates';

export default () => {
  const {
    uploadResume,
    isImporting,
    setError,
    resumes,
    uploadingResumeId,
    shouldDisableActions,
    downloadResume,
    deleteResume,
  } = useCareerCanvas();
  const [shouldOverride, _] = React.useState(false);

  const handleUploadFile = (acceptedFiles: File[]) => {
    if (isImporting) {
      return;
    }

    const acceptedFileTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ]
    if (acceptedFiles.length === 0) {
      setError("Looks like we didn't get a file. Please try again.");
      return;
    }
    if (acceptedFiles.length > 1) {
      setError("Please upload only one file at a time.");
      return;
    }
    if (acceptedFiles[0].size > 500000) {
      setError("The size of your file is too large, please upload a file under 500KB.");
      return;
    }
    if (!acceptedFileTypes.includes(acceptedFiles[0].type)) {
      setError("We can only accept PDF, DOC, and DOCX files, please convert your resume into one of those formats.");
      return;
    }
    const file = acceptedFiles[0];
    uploadResume(file, shouldOverride);
  }

  return (
    <>
      <Box>
        <Stack direction="column" rowGap={1}>
          <Typography level="h3">Import Resume</Typography>
          <Typography level="body-md">
            You can import a resume to auto-fill your Career Canvas. Or, if you'd prefer you can skip directly to the next section and fill the information out manually.
          </Typography>
        </Stack>
      </Box>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        rowGap={2}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          {/* Dropzone: https://react-dropzone.js.org/ */}
          <Dropzone onDrop={handleUploadFile}>
            {({ getRootProps, getInputProps }) => (
              <Sheet
                variant='outlined'
                sx={{
                  py: 5,
                  px:1, 
                  borderStyle: 'dashed',
                  borderWidth: 2,
                  borderRadius: 12,
                }}
              >
                <div {...getRootProps()}>
                  {
                    isImporting ? (
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        rowGap={2}
                      >
                        <CircularProgress size="lg" />
                        <Typography level="body-md">Importing your resume...</Typography>
                        <Typography level="body-xs">This may take a few minutes</Typography>
                      </Stack>
                    ) : (
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          rowGap={1}
                        >
                          <input {...getInputProps()} />
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{
                              borderRadius: 24,
                            }}
                            startDecorator={
                              <IconPlus stroke={1} />
                            }
                            onClick={() => { }}
                          >
                            Upload Resume
                          </Button>
                          <Typography level="body-sm" sx={{ textAlign: "center" }}>
                            Drag & drop your resume here, or click the button above to select it (500kb or less)
                          </Typography>
                        </Stack>
                    )
                  }
                </div>
              </Sheet>
            )}
          </Dropzone>
        </Box>

        {/* {
          resumes.length > 0 && (
            <Box>
              <Stack
                direction="column"
                rowGap={1}
              >
                <Typography level="h4">Uploaded Resumes</Typography>
                <Typography level="body-md">
                  Your Career Canvas was created from your previously uploaded resumes.
                </Typography>
                <Table variant="outlined" noWrap>
                  <thead>
                    <tr>
                      <th aria-label="FileName">File Name</th>
                      <th
                        aria-label="ImportStatus"
                        style={{ width: 100 }}
                      >
                      </th>
                      <th
                        aria-label="UploadedAt"
                        style={{ width: 120 }}
                      >
                        Uploaded At
                      </th>
                      <th
                        aria-label="Actions"
                        colSpan={2}
                        style={{ width: '100px', borderBottom: '2px solid #ccc', textAlign: 'center' }}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      resumes?.sort((a, b) =>
                        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                      ).map((resume, index) => (
                        <tr key={index}>
                          <td aria-label="FileName">{resume.name}</td>
                          <td aria-label="ImportStatus">
                            {resume._id === uploadingResumeId && <LinearProgress />}
                          </td>
                          <td aria-label="UploadedAt">
                            <Typography level="body-sm">
                              {timeSince(newDate(resume.created_at))}
                            </Typography>
                          </td>
                          <td>
                            <IconButton
                              size="sm"
                              disabled={shouldDisableActions}
                              onClick={() => downloadResume(resume._id, resume.name)}
                            >
                              <IconDownload stroke={1} />
                            </IconButton>
                          </td>
                          <td>
                            <IconButton
                              size="sm"
                              color="danger"
                              disabled={shouldDisableActions}
                              onClick={() => deleteResume(resume._id)}
                            >
                              <IconTrash stroke={1} />
                            </IconButton>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </Stack>
            </Box>
          )
        } */}
      </Stack>
    </>
  );
}
