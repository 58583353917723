import React, { useEffect } from 'react';
import {
  Grid,
} from '@mui/joy';

import MainNew from '../../components/containers/MainNew';
import Signup from './Signup/index';
import MarketingSheet from './MarketingSheet';

import { useAuth } from '../../hooks/useAuthV2';

export default () => {
  const { isAuthenticating } = useAuth();
  const signupRef = React.useRef<HTMLDivElement>(null);
  const marketingRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!marketingRef?.current) return;

    const resizeObserver = new ResizeObserver(() => {
      // @ts-ignore
      const signUp = document.getElementById('signup');
      if (signUp && marketingRef.current) {
        signUp.style.height = `${marketingRef.current.clientHeight}px`;
      }
    });

    resizeObserver.observe(marketingRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [signupRef]);


  return (
    <MainNew loading={false}>
      <Grid
        container
        spacing={2}
      >
        <Grid xs={12} md={5}>
          <Signup elementRef={signupRef}/>
        </Grid>
        <Grid xs={12} md={7}>
          <MarketingSheet elementRef={marketingRef}/>
        </Grid>
      </Grid>
    </MainNew>
  );
}
