import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typography, IconButton, Stack } from "@mui/joy";
import { useMediaQuery } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';

import MainNew from '../../components/containers/MainNew';
import CentralSheet from '../../components/containers/CentralSheet';
import ErrorSnackbar from '../../components/snackBars/ErrorSnackbar';
import SuccessSnackbar from '../../components/snackBars/SuccessSnackbar';

import NoListingsFound from './NoListingsFound';
import JobListingTable from './JobListingTable';
import ControlPanelRow from './ControlPanelRow';
import PaginationRow from './PaginationRow';
import GeneratingResumeModal from './GeneratingResumeModal';
import BulkActionPanel from './BulkActionPanel';
import GenerationsRemaining from './GenerationsRemaining';

import { useJobListings } from '../../hooks/useJobListings';
import { useProfile } from '../../hooks/useProfile';
import { useImportJobListing } from '../../hooks/useImportJobListing';
import { useGenerateResume } from '../../hooks/useGenerateResume';
import JobListingsCards from './JobListingsCards';
import ImportJobModal from './JobImportModal/';

export default () => {
  const {
    noJobListingsAvailable,
    loading,
    error,
    setError,
  } = useJobListings();

  const {
    shouldShowModal,
    setShouldShowModal,
  } = useImportJobListing();

  const {
    error: generateResumeError,
    setError: setGenerateResumeError,
    generatingResumeBuilderId,
    jobListing,
    success,
    showModal,
  } = useGenerateResume();

  const { fetchProfile } = useProfile();

  const [showSuccessSnackbar, setShowSuccessSnackbar] = React.useState(false);
  const [isLoadingJobImportModal, setIsLoadingJobImportModal] = React.useState(false);
  const isXSScreen = useMediaQuery('(max-width:600px)');

  const handleShowJobImportModal = async () => {
    setIsLoadingJobImportModal(true);
    await fetchProfile();
    setIsLoadingJobImportModal(false);
    setShouldShowModal(true);
  }

  useEffect(() => {
    if (success && !showModal) {
      setShowSuccessSnackbar(true);
    }
  }, [success]);

  return (
    <MainNew loading={loading}>
      { isXSScreen ? (
        <CentralSheet
          pageTitle="Imported Job Listings"
          px={2}
          py={2}
        >
          {
            noJobListingsAvailable ? (
              <NoListingsFound />
            ) : (
              <>
                <ControlPanelRow />
                <GenerationsRemaining />
                <Stack
                  direction='row'
                  justifyContent='flex-end'
                  alignItems='flex-end'
                  sx={{
                    py: 1,
                  }}
                >
                  <IconButton
                    variant='solid'
                    color="primary"
                    size='sm'
                    sx={{
                      width: 50,
                      borderRadius: 12,
                    }}
                    loading={isLoadingJobImportModal}
                    onClick={handleShowJobImportModal}
                  >
                    <IconPlus stroke={2} />
                  </IconButton>
                </Stack>
                <JobListingsCards />
                  <ImportJobModal
                    open={shouldShowModal}
                    close={() => setShouldShowModal(false)}
                  />
              </>
            )
          }
        </CentralSheet>
      ) : (
        <CentralSheet
          pageTitle="Imported Job Listings"
        >

          {
            noJobListingsAvailable ? (
              <NoListingsFound />
            ) : (
              <>
                <ControlPanelRow />
                <BulkActionPanel />
                <GenerationsRemaining />
                <JobListingTable />
                <PaginationRow />
              </>
            )
          }
        </CentralSheet>
      )}

      <GeneratingResumeModal />
      <ErrorSnackbar
        // Job Listings Error Snackbar
        error={error}
        setError={setError}
      />
      <ErrorSnackbar
        // Generate Resume Error Snackbar
        error={generateResumeError}
        setError={setGenerateResumeError}
      />
      <SuccessSnackbar
        open={showSuccessSnackbar}
        close={() => setShowSuccessSnackbar(false)}
      >
        <Typography level="body-md">
          We have successfully generated a customized resume for your saved job {jobListing?.title} at {jobListing?.company}. You can view it <Link to={`/resume-builder/${generatingResumeBuilderId}`}>here</Link>.
        </Typography>
      </SuccessSnackbar>
    </MainNew>
  );
}