import React from 'react';

import {
  Button,
  IconButton,
  Stack,
  Typography,
} from '@mui/joy';
import {
  IconCameraPlus,
  IconLogout,
  IconPencil,
} from '@tabler/icons-react';

// Components
import MainNew from '../../components/containers/MainNew';
import CentralSheet from '../../components/containers/CentralSheet';
import Card from '../../components/containers/Card';

import Subscription from './Subscription';

import ErrorSnackbar from '../../components/snackBars/ErrorSnackbar';
import UpdateProfileModal from './UpdateProfileModal';
import UploadPhotoModal from './UploadPhotoModal';

import { useAuth } from '../../hooks/useAuthV2';
import { useProfile } from '../../hooks/useProfile';

export default () => {
  const { setShowLogoutConfirmationModal } = useAuth();
  const {
    profile,
    loading,
    error,
    setError,
    isSaving,
    updateProfile,
    uploadPhoto,
  } = useProfile();

  const [showUpdateProfileModal, setShowUpdateProfileModal] = React.useState(false);
  const [showUploadPhotoModal, setShowUploadPhotoModal] = React.useState(false);

  return (
    <>
      <MainNew loading={loading}>
        <Stack spacing={2}>
          <CentralSheet
            pageTitle="User Profile"
          >
            <Stack
              direction="column"
              gap={3}
            >
              <Typography level="h3">
                Personal Information
              </Typography>
              <Card>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={2}
                >
                  { profile.photo_url === null ? (
                    <IconButton
                      size="lg"
                      variant="outlined"
                      sx={{
                        borderRadius: 50,
                        backgroundColor: 'white',
                        p: 3,
                      }}
                      onClick={() => setShowUploadPhotoModal(true)}
                    >
                      <IconCameraPlus stroke={1} />
                    </IconButton>
                  ) : (
                      <img
                        src={profile.photo_url}
                        alt="Profile"
                        onClick={() => setShowUploadPhotoModal(true)}
                        style={{
                          cursor: 'pointer',
                          borderRadius: 50,
                          width: 75,
                          height: 75,
                        }}
                      />
                  )}

                  <Stack
                    direction="column"
                    gap={1}
                  >
                    <Typography
                      level="title-lg"
                      endDecorator={
                        <IconButton
                          onClick={() => setShowUpdateProfileModal(true)}
                        >
                          <IconPencil stroke={1} />
                        </IconButton>
                      }
                    >
                      {profile.first_name + ' ' + profile.last_name}
                    </Typography>
                    
                    <Typography level="body-sm">
                      {profile.email}
                    </Typography>
                  </Stack>
                  
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowLogoutConfirmationModal(true)}
                    sx={{
                      borderRadius: 24,
                      ml: { xs: 0, sm: 'auto' },
                    }}
                    endDecorator={
                      <IconLogout stroke={1} />
                    }
                  >
                    Logout
                  </Button>
                </Stack>
              </Card>

              <Typography level="h3">
                Subscription
              </Typography>

              <Subscription />

              {/* <Typography level="h3">
                Integrations
              </Typography>

              <GoogleChromeIntegration /> */}
            </Stack>
          </CentralSheet>
        </Stack>
      </MainNew>
      <ErrorSnackbar
        error={error}
        setError={setError}
      />
      <UpdateProfileModal
        show={showUpdateProfileModal}
        close={() => setShowUpdateProfileModal(false)}
        firstName={profile.first_name}
        lastName={profile.last_name}
        updateProfile={updateProfile}
        isSaving={isSaving}
      />
      <UploadPhotoModal
        show={showUploadPhotoModal}
        close={() => setShowUploadPhotoModal(false)}
        uploadPhoto={uploadPhoto}
        isSaving={isSaving}
        setError={setError}
        photoUrl={profile.photo_url}
      />
    </>
  );
}