import React, { useState } from 'react';

import {
  FormControl,
  FormHelperText,
  Input,
  Stack,
  Button,
  Typography,
  IconButton,
  FormLabel,
} from '@mui/joy';
import { IconEye, IconEyeOff } from '@tabler/icons-react';

import { useAuth } from '../../../hooks/useAuthV2'
import { usePassword } from '../hooks/usePassword'

interface Props {
  email: string;
  setEmail: (email: string) => void;
  emailIsValid: boolean;
  switchToSignup: () => void;
  setError: (error: string | null) => void;
  switchToResetingPassword: () => void;
}

export default (props: Props) => {
  const { password, setPassword } = usePassword(false);
  const [ showPassword, setShowPassword ] = useState<boolean>(false);
  const { isAuthenticating, signInWithPassword } = useAuth();

  const handleSignInWithEmailAndPassword = async () => {
    if (props.emailIsValid) {
      await signInWithPassword(props.email, password);
    }
  }

  return (
    <Stack
      direction="column"
      rowGap={2}
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
      }}
    >
      <FormControl
        error={!props.emailIsValid}
        sx={{
          width: '100%',
        }}
      >
        <FormLabel>
          Enter your Email
        </FormLabel>
        <Input
          type="email"
          value={props.email}
          disabled={isAuthenticating}
          onChange={(e) => props.setEmail(e.target.value)}
          placeholder="Email"
        />
        {
          !props.emailIsValid && (
            <FormHelperText>
              Please enter a valid email address.
            </FormHelperText>
          )
        }
      </FormControl>

      <FormControl
        sx={{
          width: '100%',
        }}
      >
        <FormLabel>
          Enter your Password
        </FormLabel>
        <Input
          type={showPassword ? 'text' : 'password'}
          value={password}
          disabled={isAuthenticating}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="*********"
          endDecorator={
            showPassword ? (
              <IconButton
                onClick={() => setShowPassword(false)}
                variant="plain"
                color="neutral"
              >
                <IconEye stroke={1} />
              </IconButton>
            ) : (
              <IconButton
                  onClick={() => setShowPassword(true)}
                variant="plain"
                color="neutral"
              >
                <IconEyeOff stroke={1} />
              </IconButton>
            )
          }
        />
        <FormHelperText>
          Forgot your password?
          {" "}
          <Typography
            level='body-sm'
            color="primary"
            onClick={props.switchToResetingPassword}
            sx={{
              cursor: 'pointer',
            }}
          >
            Reset it here
          </Typography>
        </FormHelperText>
      </FormControl>

      <Button
        variant="solid"
        color="primary"
        onClick={handleSignInWithEmailAndPassword}
        loading={isAuthenticating}
        sx={{
          borderRadius: 24,
          width: '100%',
          py: 1,
        }}
      >
        Sign In with Email
      </Button>

      <Typography level="body-sm">
        Don't have an account?
        {" "}
        <Typography
          color="primary"
          onClick={props.switchToSignup}
          sx={{
            cursor: 'pointer',
          }}
        >
          Sign Up
        </Typography>
      </Typography>
    </Stack>
  );
}