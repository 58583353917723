import React from 'react';
import { useMediaQuery } from '@mui/material';
import {
  Stack,
  Sheet,
  Typography,
  Modal,
  ModalDialog,
} from '@mui/joy';

import HeaderRow from './HeaderRow';
import Progress from './Progress';
import Error from './Error';

import { useGenerateResume } from '../../../hooks/useGenerateResume';

import ActionRow from './ActionRow';
import ProgressBar from './ProgressBar';

export default () => {
  const {
    jobListing,
    showModal,
    setShowModal,
    error,
    responseStepStatus,
    navigateToResumeBuilder,
    generateResume,
    isGenerating,
  } = useGenerateResume();

  const canRetry = (jobListing?.generation_retry_attempts || 4) <= 3

  const isXSScreen = useMediaQuery('(max-width:600px)');

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <ModalDialog>
        <Sheet
          sx={{
            width: '100%',
          }}
        >
          <Stack
            direction="column"
            justifyContent="flex-start"
            spacing={2}
            sx={{
              p: isXSScreen ? 0 : 2,
            }}
          >
            <HeaderRow setShowModal={setShowModal} />

            <Typography
              level="body-sm"
              color="neutral"
              sx={{
                pl: {xs: 0, sm: 2},
              }}>
              This may take a few minutes, you can safely close this modal. You will be notified when the resume is ready.
            </Typography>

            <Sheet
              variant="outlined"
              sx={{
                my: isXSScreen ? 1 : 3,
                py: isXSScreen ? 1 : 2,
                borderRadius: 12,
              }}
            >
              <Progress error={error} responseStepStatus={responseStepStatus} />

              <Error
                error={error}
                canRetry={canRetry}
                reGenerateResume={() => {}}
              />
            </Sheet>

            <ProgressBar error={error} responseStepStatus={responseStepStatus} />

            <ActionRow
              closeModal={() => setShowModal(false)}
              isGenerating={isGenerating}
              wasError={!!error}
              canRetry={canRetry}
              successAction={navigateToResumeBuilder}
              errorAction={() => generateResume(jobListing!)}
            />

          </Stack>
        </Sheet>
      </ModalDialog>
    </Modal>
  )
}