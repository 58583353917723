import React, { useState, useEffect } from 'react';
import StytchClient from "../../../integrations/stytch";

export interface OTPProps {
  otpCode: string;
  setOtpCode: (otpCode: string) => void;
  otpIsValid: boolean;
  loading: boolean; //loading
  otpAttempts: number;
  otpSuccess: boolean;
  otpError: string | null;

  sendOTP: (email: string) => void;
  verifyOTP: () => void;
  resetOTP: () => void;
}

export const useOTP = (): OTPProps => {
  const [otpCode, setOtpCode] = useState<string>('');
  const [otpIsValid, setOtpIsValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [methodId, setMethodId] = useState<string | null>(null);
  const [otpAttempts, setOtpAttempts] = useState<number>(0);
  const [otpSuccess, setOtpSuccess] = useState<boolean>(false);
  const [otpError, setOtpError] = useState<string | null>(null);

  const sendOTP = async (email: string) => {
    setLoading(true);
    try {
      const response = await StytchClient.otps.email.loginOrCreate(email);
      setMethodId(response.method_id);
    } catch (error) {
      setOtpError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const verifyOTP = async () => {
    if (!otpIsValid) {
      return;
    }

    setLoading(true);
    setOtpAttempts(otpAttempts + 1);
    try {
      // This method sets a session token in the browser
      await StytchClient.otps.authenticate(
        otpCode,
        methodId!,
        { session_duration_minutes: 60 }
      );
      setOtpSuccess(true);
      setOtpError(null);
      setOtpAttempts(0);
    } catch (error) {
      setOtpError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const resetOTP = () => {
    setOtpCode('');
    setOtpIsValid(false);
    setLoading(false);
    setMethodId(null);
    setOtpAttempts(0);
    setOtpError(null);
  }

  useEffect(() => {
    setOtpIsValid(otpCode.length === 6);
  }, [otpCode]);

  return {
    otpCode,
    setOtpCode,
    otpIsValid,
    loading,
    otpAttempts,
    otpSuccess,
    otpError,

    sendOTP,
    verifyOTP,
    resetOTP,
  };
}