import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import Cookies from 'js-cookie';

import { useLocalStorage } from './useLocalStorage';
import * as API from '../integrations/api';
import StytchClient from "../integrations/stytch"

import ResumeRoverAPIError from '../util/error';

import { useConfig } from './useConfig';

export interface AuthContextProps {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  authenticationError: string | null;
  authenticateOAuth: (token: string) => Promise<void>;
  signUpWithPassword: (password: string) => Promise<void>;
  signInWithPassword: (email: string, password: string) => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  resetPassword: (token: string, password: string) => Promise<void>;

  logout: () => void;
  shouldShowLogoutConfirmationModal: boolean;
  setShowLogoutConfirmationModal: (value: boolean) => void;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export default ({ children }) => {
  const authLoginErrorMessage = "Sorry, we were unable to log you in. Please check your login credentials and try again.";
  const { cookieDomain } = useConfig();

  const [_, setProfile] = useLocalStorage("user", null);
  const [ isAuthenticated, setIsAuthenticated ] = useState<boolean>(false);
  const [ isAuthenticating, setIsAuthenticating ] = useState<boolean>(false);
  const [ authenticationError, setAuthenticationError ] = useState<string | null>(null);

  const [ sessionToken, setSessionToken ] = useLocalStorage("sessionToken", null);
  const [ sessionJWT, setSessionJWT ] = useLocalStorage("sessionJWT", null);

  const [ shouldShowLogoutConfirmationModal, setShowLogoutConfirmationModal ] = useState<boolean>(false);

  useEffect(() => {
    setIsAuthenticated(sessionToken !== null);
  }, [sessionToken]);

  const handleSuccessfulAuth = async (session_token: string, session_jwt: string) => {
    try {
      const response = await API.Authentication.authenticate("session", session_token);

      Cookies.set("auth", session_token, {
        expires: (1 / 12), // one hour
        domain: cookieDomain,
      });

      setProfile(response.user);
      setSessionToken(session_token);
      setSessionJWT(session_jwt);
      setIsAuthenticated(true);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        setAuthenticationError(error.message);
      } else {
        setAuthenticationError(authLoginErrorMessage);
      }
    }
  }

  const authenticateOAuth = async (token: string): Promise<void> => {
    setIsAuthenticating(true);
    try {
      const response = await StytchClient
        .oauth
        .authenticate(token, { session_duration_minutes: 60 })

      await handleSuccessfulAuth(response.session_token, response.session_jwt);
    } catch (error) {
      setAuthenticationError(authLoginErrorMessage);
    } finally {
      setIsAuthenticating(false);
    }
  }

  const signUpWithPassword = async (password: string) => {
    setIsAuthenticating(true);
    try {
      const response = await StytchClient
        .passwords
        .resetBySession({
          password,
          session_duration_minutes: 60,
        });

      await handleSuccessfulAuth(response.session_token, response.session_jwt);
    } catch (error) {
      setAuthenticationError(authLoginErrorMessage);
    } finally {
      setIsAuthenticating(false);
    }
  }

  const signInWithPassword = async (email: string, password: string): Promise<void> => {
    setIsAuthenticating(true);
    try {
      const response = await StytchClient
        .passwords
        .authenticate({
          email,
          password,
          session_duration_minutes: 60,
        });

      await handleSuccessfulAuth(response.session_token, response.session_jwt);
    } catch (error) {
      setAuthenticationError(authLoginErrorMessage);
    } finally {
      setIsAuthenticating(false);
    }
  }

  const sendPasswordResetEmail = async (email: string): Promise<void> => {
    // https://stytch.com/docs/sdks/passwords/reset-by-email-start
    setIsAuthenticating(true);
    try {
      await StytchClient
        .passwords
        .resetByEmailStart({
          email,
          reset_password_redirect_url: `${window.location.origin}/reset-password`,
        });
    } catch (error) {
      setAuthenticationError("Sorry, we were unable to send a password reset email to that address. Please try again.");
    } finally {
      setIsAuthenticating(false);
    }
  }

  const resetPassword = async (token: string, password: string): Promise<void> => {
    // https://stytch.com/docs/sdks/passwords/reset-by-email
    setIsAuthenticating(true);
    try {
      const response = await StytchClient
        .passwords
        .resetByEmail({
          token,
          password,
          session_duration_minutes: 60,
        });
        await handleSuccessfulAuth(response.session_token, response.session_jwt);
    } catch (error) {
      setAuthenticationError("Sorry, we were unable to reset your password. Please try again.");
    } finally {
      setIsAuthenticating(false);
    }
  }

  const logout = () => {
    setSessionToken(null);
    setSessionJWT(null);
    setShowLogoutConfirmationModal(false);
    Cookies.remove("auth", {
      path: "",
      domain: cookieDomain,
    });
    setIsAuthenticated(false);
  }

  const value: AuthContextProps = useMemo(() => ({
    isAuthenticated,
    isAuthenticating,
    authenticationError,
    
    authenticateOAuth,
    signUpWithPassword,
    signInWithPassword,
    sendPasswordResetEmail,
    resetPassword,

    logout,
    shouldShowLogoutConfirmationModal,
    setShowLogoutConfirmationModal,
  }), [
    isAuthenticated,
    isAuthenticating,
    authenticationError,
    authenticateOAuth,
    signUpWithPassword,
    shouldShowLogoutConfirmationModal,
  ]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () =>
  useContext(AuthContext);
