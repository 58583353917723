import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../../../hooks/useAuthV2';
import { useProfile } from '../../../hooks/useProfile';

import { Drawer } from '@mui/joy';

import {
  IconAddressBook,
  IconLogout,
  IconUserCircle,
  IconList,
} from '@tabler/icons-react';

// @ts-ignore
import headerLogo from '../../../../assets/Header-Black.png';

import {
  Box,
  Stack,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemDecorator,
  ListItemContent,
} from '@mui/joy';

interface Props {
  drawerOpen: boolean;
  handleDrawerToggle: () => void;
}

export default (props: Props) => {
  const { logout } = useAuth();
  const { profile } = useProfile();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Drawer
      open={props.drawerOpen}
      onClose={props.handleDrawerToggle}
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            py: 2,
            height: '100vh',
          }}
        >
          <Box sx={{ width: "100%" }}>
            <img src={headerLogo} alt="header logo" style={{ width: 150, marginTop: 3 }} />
            <Divider />
          </Box>

          <List sx={{ width: "100%" }}>
            { /* Job Listings */}
            <ListItem>
              <ListItemButton
                variant={location.pathname === "/" ? "soft" : "plain"}
                onClick={() => navigate("/")}
                sx={{ py: 2 }}
              >
                <ListItemDecorator>
                  <IconList stroke={1} />
                </ListItemDecorator>
                <ListItemContent sx={{ textAlign: "left" }}>
                  Job Listings
                </ListItemContent>
              </ListItemButton>
            </ListItem>

            { /* Career Canvas */}
            <ListItem>
              <ListItemButton
                variant={location.pathname === "/career-canvas" ? "soft" : "plain"}
                onClick={() => navigate("/career-canvas")}
                sx={{ py: 2 }}
              >
                <ListItemDecorator>
                  <IconAddressBook stroke={1} />
                </ListItemDecorator>
                <ListItemContent sx={{ textAlign: "left" }}>
                  Career Canvas
                </ListItemContent>
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton
                variant={location.pathname === "/profile" ? "soft" : "plain"}
                onClick={() => navigate("/profile")}
                sx={{ py: 2 }}
              >
                <ListItemDecorator>
                  <IconUserCircle stroke={1} />
                </ListItemDecorator>
                <ListItemContent sx={{ textAlign: "left" }}>
                  Profile
                </ListItemContent>
              </ListItemButton>
            </ListItem>

          </List>

          <List
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <ListItem>
              <ListItemButton
                variant="plain"
                onClick={logout}
                sx={{ py: 2 }}
              >
                <ListItemDecorator>
                  <IconLogout stroke={1} />
                </ListItemDecorator>
                <ListItemContent sx={{ textAlign: "left" }}>
                  Logout
                </ListItemContent>
              </ListItemButton>
            </ListItem>

            <ListItem
              sx={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <ListItemDecorator>
                {profile?.photo_url ? (
                  <img
                    src={profile.photo_url}
                    alt="Profile"
                    style={{
                      borderRadius: '50%',
                      width: '25px',
                      height: '25px',
                    }}
                  />
                ) : (
                  <IconUserCircle stroke={1} />
                )}
              </ListItemDecorator>
              <ListItemContent>
                {
                  profile?.first_name && profile?.last_name ?
                    `${profile.first_name} ${profile.last_name}` :
                    'Account'
                }
              </ListItemContent>
            </ListItem>
          </List>
        </Stack>
      </Box>
    </Drawer>
  );
}
