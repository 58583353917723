import React, { useEffect } from 'react';
import {
  Stack,
  IconButton,
  Modal,
  ModalDialog,
  Typography,
  ModalOverflow,
  Sheet,
  Button,
  Alert,
  Box,
  LinearProgress
} from '@mui/joy';
import { IconX, IconRosetteDiscountCheck } from '@tabler/icons-react'
import InputForm from './InputForm';
import TextAreaForm from './TextAreaForm';

import { useProfile } from '../../../hooks/useProfile';
import { useJobListings } from '../../../hooks/useJobListings';
import { useImportJobListing } from '../../../hooks/useImportJobListing';

// @ts-ignore
import headerLogo from '../../../../assets/iconlight128.png';
import ImportsRemaining from './ImportsRemaining';

interface Props {
  open: boolean;
  close: () => void;
}

export default (props: Props) => {
  const { fetchJobListings } = useJobListings();
  const {
    isSubmitting,
    error,
    success,
    url,
    setUrl,
    urlIsValid,
    jobTitle,
    setJobTitle,
    jobTitleIsValid,
    companyName,
    setCompanyName,
    companyNameIsValid,
    jobListingContent,
    setJobListingContent,
    canSubmit,
    importJobListing,
    resetImportJobListing,
  } = useImportJobListing();
  const { profile } = useProfile();
  const [ hasImportsRemaining, setHasImportsRemaining ] = React.useState(false);

  useEffect(() => {
    setHasImportsRemaining(profile.usage.total_grants - profile.usage.total_imports_used > 0);
  }, [
    profile.usage.total_grants,
    profile.usage.total_imports_used,
  ]);

  const validationText = "You can only include letters, numbers, spaces, and some special characters (- _ . ~ : , ( ) &).";

  const handleSaveJobListing = async () => {
    await importJobListing();
    await fetchJobListings();
  }

  const renderContent = () => {
    if (isSubmitting) {
      return (
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: "80%",
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              pb: 4,
            }}
          >
            <img
              src={headerLogo}
              alt="fetching your data"
              style={{ width: 128, marginTop: 100 }}
            />

            <LinearProgress
              size="lg"
              variant="solid"
              sx={{
                my: 3,
              }}
            />

            <Typography level="body-md">
              Importing job listing...
            </Typography>
          </Box>
        </Stack>
      );
    }

    if (success) {
      return (
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '100%',
            pb: 2,
          }}
        >
          <Typography level="title-lg" color="success">
            <IconRosetteDiscountCheck size={64} />
          </Typography>
          <Typography level="title-md" color="success">
            Job Listing Saved!
          </Typography>
          <Typography level="body-md" sx={{ textAlign: "center" }}>
            We successfully saved your job listing of <strong>{jobTitle || "Not Provided"}</strong> at <strong>{companyName || "Not Provided"}</strong>!
          </Typography>
          <Button
            variant="outlined"
            color="neutral"
            onClick={resetImportJobListing}
            sx={{
              mb: 2,
            }}
          >
            Close
          </Button>
        </Stack>
      );
    }

    return (
      <Stack
        direction="column"
        gap={2}
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          py: 2,
          px: 1,
          width: "100%"
        }}
      >
        {error && (
          <Alert
            variant="outlined"
            color="danger"
            sx={{
              width: "100%"
            }}
          >
            {error}
          </Alert>
        )}

        <InputForm
          label="Job Listing URL (optional)"
          name="jobListingUrl"
          infoText="The URL of the job listing you are importing."
          value={url}
          onChange={setUrl}
          warningText={
            urlIsValid ? undefined : "Please enter a valid URL."
          }
        />

        <InputForm
          label="Job Title (optional)"
          name="jobTitle"
          infoText="If you don't include this, we'll try to infer this from the job description."
          value={jobTitle}
          onChange={setJobTitle}
          warningText={
            jobTitleIsValid ? undefined : validationText
          }
        />

        <InputForm
          label="Company (optional)"
          name="company"
          infoText="If you don't include this, we'll try to infer this from the job description."
          value={companyName}
          onChange={setCompanyName}
          warningText={
            companyNameIsValid ? undefined : validationText
          }
        />

        <TextAreaForm
          label="Job Listing Content (required)"
          name="jobListingContent"
          infoText="The content of the job listing you are importing."
          value={jobListingContent}
          onChange={setJobListingContent}
          warningText={
            jobListingContent.length > 1000 ? undefined : "Job Listing should be 1,000 characters or more."
          }
        />

        <ImportsRemaining />

        <Stack
          direction="row"
          gap={2}
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            width: "100%"
          }}
        >
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => props.close()}
          >
            Cancel
          </Button>

          <Button
            variant="solid"
            color="primary"
            disabled={!canSubmit || isSubmitting || !hasImportsRemaining}
            onClick={handleSaveJobListing}
          >
            Save Job Listing
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Modal
      open={props.open}
      onClose={props.close}
    >
      <ModalOverflow>
        <ModalDialog
          color="neutral"
          variant="soft"
          sx={{
            minWidth: { xs: "100%", sm: 600, },
          }}
        >
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="space-between"
            sx={{
              width: '100%',
            }}
          >
            <Typography level="h3">Import Job Listing</Typography>
            <IconButton
              variant="plain"
              onClick={() => props.close()}
            >
              <IconX stroke={1} />
            </IconButton>
          </Stack>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            gap={2}
          >
            <Sheet
              variant='outlined'
              sx={{
                width: "100%",
                borderRadius: 12,
              }}
            >
              
              {renderContent()}
            </Sheet>
          </Stack>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}
