import React, { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import {
  FormControl,
  FormLabel,
  Stack,
  Typography,
  Input,
  IconButton,
  FormHelperText,
  List,
  ListItem,
  ListItemDecorator,
  Button,
} from '@mui/joy';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import MainNew from '../../components/containers/MainNew';
import CentralSheet from '../../components/containers/CentralSheet';

import { useAuth } from '../../hooks/useAuthV2';
import { usePassword } from './hooks/usePassword';

export default () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [showConfirmPassword, setShowConfirmPassword] = React.useState<boolean>(false);
  const [passwordsMatch, setPasswordsMatch] = React.useState<boolean>(false);

  const {
    isAuthenticating,
    isAuthenticated,
    resetPassword,
    authenticationError,
  } = useAuth();

  const {
    password,
    setPassword,
    passwordIsValid,
    passwordFeedback,
    passwordSuggestions,
  } = usePassword();

  const handlePasswordReset = async () => {
    if (!token || !passwordIsValid || !passwordsMatch) {
      return;
    }
    await resetPassword(token, password);
  }

  useEffect(() => {
    setPasswordsMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const renderInnerContent = () => {
    if (!token) {
      return (
        <Typography level="title-md" sx={{ textAlign: "center" }}>
          This link is invalid. Please request a <Link to="/login">new password reset link</Link>.
        </Typography>
      );
    }

    return (
      <Stack
        direction="column"
        rowGap={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
        }}
      >
        <Typography level="h1">
          Reset My Password
        </Typography>

        <FormControl
          error={!passwordIsValid}
          sx={{
            width: '100%',
          }}
        >
          <FormLabel>
            Enter your New Password
          </FormLabel>
          <Input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="*********"
            endDecorator={
              showPassword ? (
                <IconButton
                  onClick={() => setShowPassword(false)}
                  variant="plain"
                  color="neutral"
                >
                  <IconEye stroke={1} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => setShowPassword(true)}
                  variant="plain"
                  color="neutral"
                >
                  <IconEyeOff stroke={1} />
                </IconButton>
              )
            }
          />
          {
            !passwordIsValid && (
              <>
                <FormHelperText>
                  Your password is not strong enough. {passwordFeedback}
                </FormHelperText>
                <List>
                  {
                    passwordSuggestions.map((suggestion, index) => (
                      <ListItem key={index}>
                        <ListItemDecorator>
                          <Typography level="body-xs">
                            •
                          </Typography>
                        </ListItemDecorator>
                        <Typography level="body-xs">
                          {suggestion}
                        </Typography>
                      </ListItem>
                    ))}
                </List>
              </>
            )
          }
        </FormControl>

        <FormControl
          error={!passwordsMatch}
          sx={{
            width: '100%',
          }}
        >
          <FormLabel>
            Confirm your New Password
          </FormLabel>
          <Input
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="*********"
            endDecorator={
              showConfirmPassword ? (
                <IconButton
                  onClick={() => setShowConfirmPassword(false)}
                  variant="plain"
                  color="neutral"
                >
                  <IconEyeOff stroke={1} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => setShowConfirmPassword(true)}
                  variant="plain"
                  color="neutral"
                >
                  <IconEye stroke={1} />
                </IconButton>
              )
            }
          />
          {
            !passwordsMatch && (
              <FormHelperText>
                Your passwords do not match.
              </FormHelperText>
            )
          }
        </FormControl>

        <Button
          variant="solid"
          color="primary"
          onClick={handlePasswordReset}
          loading={isAuthenticating}
          disabled={
            password.length === 0 ||
            !passwordIsValid ||
            !passwordsMatch
          }
          sx={{
            borderRadius: 24,
            width: '100%',
            py: 1,
          }}
        >
          Reset My Password
        </Button>

        {
          authenticationError && (
            <FormHelperText>
              {authenticationError}
            </FormHelperText>
          )
        }
      </Stack>
    );
  }

  return (
    <MainNew>
      <CentralSheet
        pageTitle="Reset Password"
      >
        {renderInnerContent()}
      </CentralSheet>
    </MainNew>
  );
}
